import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  OrderList: [
    {
      orderId: "",
      restaurantName: "",
      source: "",
      dateTime: "",
      totalAmount: 0,
    },
  ],
  HistoryList: [
    {
      text: "",
      type: "",
      userId: "",
      points: 0,
      createdAt: "",
      updatedAt: "",
      id: "",
    },
  ],
  CouponList: {
    coupons: [
      {
        orderTime: "",
        restaurantName: "",
        customerName: "",
        couponName: "",
        totalCouponsUsed: 0,
      },
    ],
    totalCouponsCount: 0,
  },
  RestaurantsList: {
    restaurants: [
      {
        lastOrderDate: "",
        restaurantName: "",
        visitCount: 0,
      },
    ],
    totalVisitCount: 0,
    customerPhoneNumber: "",
  },
  customerList: [
    {
      _id: {
        email: "",
        phone: "",
      },
      name: "",
      email: "",
      phone: "",
      address: "",
    },
  ],
  customerData: {
    totalOrders: 0,
    totalSpend: 0,
    avgSpend: 0,
    couponsRedeemed: 0,
    totalPoints: 0,
    lastOrderDate: "",
    uniqueRestaurantsVisited: 0,
    name: "",
  },
  TotalPageCount: 1,
};

export const CustomerDetailsSlice = createSlice({
  name: "customerDetails",
  initialState,
  reducers: {
    setCustomerList: (state, action) => {
      state.customerList = action.payload;
    },
    setCustomerData: (state, action) => {
      state.customerData = action.payload;
    },
    setOrderList: (state, action) => {
      state.OrderList = action.payload;
    },
    setCouponList: (state, action) => {
      state.CouponList = action.payload;
    },
    setCustomerRestaurantList: (state, action) => {
      state.RestaurantsList = action.payload;
    },
    setCustomerTotalPageCount: (state, action) => {
      state.TotalPageCount = action.payload;
    },
    setCustomerHistoryList: (state, action) => {
      state.HistoryList = action.payload;
    },
  },
});

export const {
  setCustomerData,
  setCustomerList,
  setOrderList,
  setCouponList,
  setCustomerRestaurantList,
  setCustomerTotalPageCount,
  setCustomerHistoryList,
} = CustomerDetailsSlice.actions;
export default CustomerDetailsSlice.reducer;
