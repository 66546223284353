// Header.js
import React from "react";
import { useLocation } from "react-router-dom";
import "./header.scss";
import { Box, Typography } from "@mui/material";
import { routeTitles } from "../../services/common/commonFunctions";
export default function Header() {
  const location = useLocation();
  const title = routeTitles[location.pathname] || "Default Title";

  const headerWrapStyles = {
    padding: "18px 26px",
    background: "white",
    display: "flex",
    justifyContent: "space-between",
  };

  const headingStyles = {
    color: "#C0201E",
    fontSize: "18px",
    fontWeight: "600",
    fontFamily: "Reem Kufi",
    textTransform: "uppercase",
  };

  return (
    <Box sx={headerWrapStyles}>
      <Typography sx={headingStyles}>{title}</Typography>
      {/* Additional Header Content */}
    </Box>
  );
}
