import * as React from "react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../redux/store";
import { setToken, setUserData } from "../redux/features/auth";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Search from "../assets/icons/iconsStatic/search.svg";
import DvrIcon from "@mui/icons-material/Dvr";
// import SettingsIcon from "@mui/icons-material/Settings";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import logo from "../assets/images/foodies-logo-main.png";
import logoClosed from "../assets/images/foodies-logo-closed.svg";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import billOutlineIcon from "../assets/icons/billOutlineIcon.svg";
import DescriptionIcon from "@mui/icons-material/Description";
import {
  ChartArrowOutline,
  ChatNotificationOutline,
  DollarConversion,
  GraphInLens,
  IdCardOutline,
  IdeaOutline,
  ShippingDeliveryOutline,
  WidgesOutline,
  SettingsIcon,
  BellIcon,
} from "../assets/icons/iconComponents";
import { ListItemIcon } from "@mui/material";
import Header from "../Components/Header/Header";
import logoutIcon from "../assets/icons/iconsStatic/logout.svg";
import "./navigation.scss";
interface IProps {
  children: React.ReactNode;
}

const drawerWidth: number = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));
export const Layout = ({ children }: IProps) => {
  const routeLocation = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  //state
  const [open, setOpen] = React.useState(true);
  const [promotionOpen, setPromotionOpen] = React.useState(false);

  const gotoPromotion = () => {
    // Toggle the state when clicking on Promotion menu item
    setPromotionOpen(!promotionOpen);
  };
  React.useEffect(() => {
    // Check if the location starts with "/promotion" and set promotionOpen accordingly
    if (routeLocation.pathname.startsWith("/promotion")) {
      setPromotionOpen(true);
    } else {
      setPromotionOpen(false);
    }
  }, [routeLocation.pathname]);

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const gotoOrders = () => {
    navigate("/orders");
  };
  const gotoCoupons = () => {
    navigate("/coupons");
  };
  const gotoPromotionalCoupons = () => {
    navigate("/promotional/coupon");
  };
  const gotoPromotionalWheel = () => {
    navigate("/promotional/wheel");
  };
  const gotoPromotionalAdvertisement = () => {
    navigate("/promotional/advertiser");
  };

  const gotoCampaign = () => {
    navigate("/promotional/campaign");
  };
  const gotoPoints = () => {
    navigate("/promotional/points");
  };
  const gotoReports = () => {
    navigate("/promotional/reports");
  };
  const gotoConversions = () => {
    navigate("/conversion");
  };
  const gotoDashboard = () => {
    navigate("/dashboard");
  };

  const gotoSettings = () => {
    navigate("/settings");
  };
  const gotoTermsAndConditions = () => {
    navigate("/termsAndConditions");
  };
  const gotoLogout = () => {
    dispatch(setUserData({}));
    dispatch(setToken(""));
    navigate("/");
  };
  const gotoInsight = () => {
    navigate("/insight");
  };
  const gotoCustomer = () => {
    navigate("/customer");
  };

  const gotoNotifications = () => {
    navigate("/notifications");
  };

  const gotoRestaurantListing = () => {
    navigate("/restaurant-listing");
  };
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Drawer
          variant="permanent"
          open={open}
          className={open ? "mainSidebar" : "mainSidebar collapsed"}
        >
          <Box
            bgcolor="#FFFFFF"
            color="#5E768D"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100vh",
              borderRight: "1px solid #D56F6E",
            }}
          >
            <Box>
              <Toolbar
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  px: [1],
                  height: 60,
                  minHeight: "0 !important",
                }}
                className="toolbar"
              >
                <Box
                  sx={{
                    maxWidth: 160,
                    mx: "auto",
                    // py: [2],

                    "> img": {
                      display: "block",
                    },
                  }}
                  className="logo"
                >
                  {!open ? (
                    <img alt="" src={logoClosed} />
                  ) : (
                    <img alt="" src={logo} height="35px" />
                  )}
                </Box>
              </Toolbar>

              {/* <Divider
                sx={{
                  background: "rgba(246, 250, 255, 1)",
                  marginLeft: 1,
                  marginRight: 1,
                }}
              /> */}
              {/* <Box py={3}>
                <div className="inputSearchV1">
                  <img src={Search} alt="" className="icon" />
                  <input type="text" placeholder="Search" />
                </div>
              </Box> */}

              <Divider
                sx={{
                  background: "rgba(246, 250, 255, 1)",
                  marginLeft: 1,
                  marginRight: 1,
                }}
              />
              <MenuList
                sx={{ pt: 0, textAlign: "center", px: "15px" }}
                className="MenuList"
              >
                <Typography
                  mb={0}
                  my={3}
                  ml={4}
                  sx={{
                    fontSize: "10px",
                    fontWeight: 600,
                    textAlign: "left",
                    fontFamily: "Reem Kufi",
                  }}
                >
                  MENU
                </Typography>
                <MenuItem
                  onClick={gotoDashboard}
                  selected={routeLocation.pathname === "/dashboard"}
                >
                  <span>
                    <WidgesOutline />
                  </span>
                  <ListItemText>{open ? "Dashboard" : ""}</ListItemText>
                </MenuItem>
                {/* Display Coupons and Wheel submenus only when Promotion is open */}
                <MenuItem
                  onClick={gotoCoupons}
                  selected={routeLocation.pathname === "/coupons"}
                  sx={{
                    "&:selected": {
                      backgroundColor: "#D9D9D9",
                    },
                  }}
                >
                  <span>
                    <GraphInLens />
                  </span>
                  <ListItemText>{open ? "Coupons" : ""}</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={gotoCustomer}
                  selected={routeLocation.pathname === "/customer"}
                  sx={{
                    "&:selected": {
                      backgroundColor: "#D9D9D9",
                    },
                  }}
                >
                  <span>
                    {/* <GraphInLens /> */}
                    <IdCardOutline />
                  </span>
                  <ListItemText>{open ? "Customers" : ""}</ListItemText>
                </MenuItem>{" "}
                <MenuItem
                  onClick={gotoConversions}
                  selected={routeLocation.pathname === "/conversion"}
                  sx={{
                    "&:selected": {
                      backgroundColor: "#D9D9D9",
                    },
                  }}
                >
                  <span>
                    <DollarConversion />
                  </span>
                  <ListItemText>{open ? "Conversion" : ""}</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={gotoInsight}
                  selected={routeLocation.pathname === "/insight"}
                  sx={{
                    "&:selected": {
                      backgroundColor: "#D9D9D9",
                    },
                  }}
                >
                  <span>
                    {/* <FactCheckIcon fontSize="small" /> */}
                    <IdeaOutline />
                  </span>
                  <ListItemText>{open ? "Insights" : ""}</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={gotoOrders}
                  selected={routeLocation.pathname === "/orders"}
                  sx={{
                    "&:selected": {
                      backgroundColor: "#D9D9D9",
                    },
                  }}
                >
                  <span>
                    <ShippingDeliveryOutline />
                  </span>
                  <ListItemText>{open ? "Orders" : ""}</ListItemText>
                </MenuItem>
                <MenuItem
                  sx={{
                    display: "block",
                    padding: "0 !important",
                  }}
                  // className="CollapsibleList opened"
                  // promotionOpen
                  className={`CollapsibleList ${promotionOpen && "opened"}`}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.75rem",
                    }}
                    py={2}
                    px={3}
                    onClick={gotoPromotion}
                  >
                    <span>
                      <ChartArrowOutline />
                    </span>
                    <ListItemText>{open ? "Promotions" : ""}</ListItemText>
                  </Box>

                  {promotionOpen && (
                    <MenuList className="menulistV1">
                      {/* Submenu for Coupons */}
                      <MenuItem
                        onClick={gotoPromotionalCoupons}
                        selected={
                          routeLocation.pathname === "/promotional/coupon"
                        }
                      >

                        <ListItemText>{open ? "Coupons" : ""}</ListItemText>
                      </MenuItem>
                      <MenuItem
                        onClick={gotoPromotionalWheel}
                        selected={
                          routeLocation.pathname === "/promotional/wheel"
                        }
                      >
                        <ListItemText>{open ? "Wheel" : ""}</ListItemText>
                      </MenuItem>
                      <MenuItem
                        onClick={gotoPromotionalAdvertisement}
                        selected={
                          routeLocation.pathname === "/promotional/advertiser"
                        }
                        sx={{
                          "&:selected": {
                            backgroundColor: "#D9D9D9",
                          },
                        }}
                      >
                        <ListItemText>{open ? "Advertiser" : ""}</ListItemText>
                      </MenuItem>
                      <MenuItem
                        onClick={gotoPoints}
                        selected={
                          routeLocation.pathname === "/promotional/points"
                        }
                        sx={{
                          "&:selected": {
                            backgroundColor: "#D9D9D9",
                          },
                        }}
                      >
                        {/* <span>
                          <ShippingDeliveryOutline />
                        </span> */}
                        <ListItemText>{open ? "Points" : ""}</ListItemText>
                      </MenuItem>{" "}
                      <MenuItem
                        onClick={gotoCampaign}
                        selected={
                          routeLocation.pathname === "/promotional/campaign"
                        }
                        sx={{
                          "&:selected": {
                            backgroundColor: "#D9D9D9",
                          },
                        }}
                      >
                        {/* <span>
                          <ShippingDeliveryOutline />
                        </span> */}
                        <ListItemText>{open ? "Campaign" : ""}</ListItemText>
                      </MenuItem>{" "}
                      <MenuItem
                        onClick={gotoReports}
                        selected={
                          routeLocation.pathname === "/promotional/reports"
                        }
                        sx={{
                          "&:selected": {
                            backgroundColor: "#D9D9D9",
                          },
                        }}
                      >
                        <ListItemText>{open ? "Reports" : ""}</ListItemText>
                      </MenuItem>
                      {/* Submenu for Wheel */}
                    </MenuList>
                  )}
                </MenuItem>
                <MenuItem
                  onClick={gotoNotifications}
                  selected={routeLocation.pathname === "/notifications"}
                >
                  <span>
                    <BellIcon />
                  </span>
                  <ListItemText>{open ? "Notifications" : ""}</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={gotoRestaurantListing}
                  selected={routeLocation.pathname === "/restaurant-listing"}
                >
                  <span>
                    <RestaurantMenuIcon />
                  </span>
                  <ListItemText>{open ? "RESTAURANT" : ""}</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={gotoSettings}
                  selected={routeLocation.pathname === "/settings"}
                  sx={
                    {
                      // "&:selected": {
                      //   backgroundColor: "#D9D9D9", // Replace with your desired hover color
                      // },
                      //  backgroundColor:
                      //      routeLocation.pathname === "/settings" ? "red" : "blue",
                    }
                  }
                >
                  <span>
                    {/* <SettingsIcon fontSize="small" /> */}
                    <SettingsIcon />
                  </span>
                  <ListItemText>{open ? "Settings" : ""}</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={gotoTermsAndConditions}
                  selected={routeLocation.pathname === "/termsAndConditions"}
                >
                  <span>
                    <DescriptionIcon />
                  </span>
                  <ListItemText>
                    {open ? "Terms & Conditions" : ""}
                  </ListItemText>
                </MenuItem>
              </MenuList>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <IconButton
                sx={{
                  color: "#CD4D4B",
                  margin: "0.5rem 1rem 1.5rem auto",
                  background: "#ffefe4",
                  borderRadius: "0.5rem",
                }}
                onClick={toggleDrawer}
                className="squareBtnV1"
              >
                <ChevronLeftIcon />
              </IconButton>

              <Divider
                sx={{
                  background: "rgba(246, 250, 255, 1)",
                  marginLeft: 1,
                  marginRight: 1,
                }}
              />
              <Typography
                bgcolor="#FFFFFF"
                onClick={gotoLogout}
                noWrap
                py={4}
                sx={{
                  flexGrow: 1,
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "center",
                  fontSize: 12,
                  fontWeight: 600,
                  letterSpacing: "0.24px",
                  textTransform: "uppercase",
                  gap: "12px",
                  paddingLeft: "2.5rem",
                  fontFamily: "Reem Kufi",
                }}
              >
                <img src={logoutIcon} alt="logout" />
                {!open ? null : <span>{open ? "Logout" : ""}</span>}
              </Typography>
            </Box>
          </Box>
        </Drawer>
        <Box sx={{ flex: "1" }}>
          <Header />
          {children}
        </Box>
      </Box>
    </>
  );
};
