export const formatOrdersDate = (date: string | Date) => {
  return new Date(date).toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
};
export const formatDateToMonthYear = (date: string | Date) => {
  return new Date(date).toLocaleString("en-US", {
    month: "short",
    day: "2-digit",
  });
};
export const formatDateToTime = (date: string | Date) => {
  return new Date(date).toLocaleString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
};

export const formatLargeNumber = (number: number): string => {
  // Check if the number is greater than or equal to 1000
  if (number >= 1000) {
    // Round the number to the nearest whole number
    number = Math.round(number);

    // Convert the number to a string and split it into parts
    const parts = number.toString().split(".");

    // Add commas to the integer part of the number
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Join the parts back together with a period for decimal numbers
    return parts.join(".");
  } else {
    // If the number is less than 1000, format it with up to two decimal places
    return parseFloat(number.toFixed(2)).toString();
  }
};

export const formatKeyToReadable = (key: string) => {
  // Split the key on non-alphabetic characters (e.g., camelCase, snake_case)
  const words = key?.split(/[^a-zA-Z0-9]+/);

  // Capitalize the first letter of each word and join them with spaces
  const readable = words
    ?.map((word) => {
      if (word.match(/^\d+$/)) {
        // If the word is all digits, treat it as a number
        return word;
      } else {
        // Otherwise, capitalize the first letter
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
    })
    .join(" ");

  return readable;
};

// Example of using the function

export const getSourceType = (source: string) => {
  if (source) {
    if (source === "app") {
      return "App";
    } else if (source === "BiteOrder") {
      return "Web";
    }
  }
  return "3rd Party";
};

export function formatNumberToOneDecimalPlace(number: any) {
  // Check if the input is a valid number
  if (typeof number !== "number" || isNaN(number)) {
    // Handle invalid input (e.g., non-numeric values)
    return "Invalid Number";
  }

  // Round the number to one decimal place and convert it back to a string
  const formattedNumber = number?.toFixed(1);

  // Remove trailing zero if present
  return formattedNumber.replace(/\.0$/, "");
}

export function formatNumberToTwoDecimalPlace(number: any) {
  // Check if the input is a valid number
  if (typeof number !== "number" || isNaN(number)) {
    // Handle invalid input (e.g., non-numeric values)
    return "Invalid Number";
  }

  // Round the number to one decimal place and convert it back to a string
  const formattedNumber = number?.toFixed(2);

  // Remove trailing zero if present
  return formattedNumber.replace(/\.0$/, "");
}
export const getColorBasedOnRepetition = (repetitionCount: any) => {
  if (repetitionCount <= 3) {
    return "blue";
  } else if (repetitionCount >= 4 && repetitionCount <= 10) {
    return "red";
  } else if (repetitionCount >= 11 && repetitionCount <= 20) {
    return "black";
  } else {
    return "green";
  }
};

export const fetchCustomerDetailsData = async (
  dispatch: any,
  action: any,
  params: any
) => {
  try {
    dispatch(action(params));
  } catch (error) {
    console.error("Error fetching data", error);
    // Handle error as needed
  }
};
export const getOrderCountByPostalCode = (postalCode: string, data: any) => {
  if (!data) {
    console.error("Data is undefined or null.");
    return 0; // or handle the case accordingly
  }

  const entry = data.find((item: any) => item?.postalCode === postalCode);
  return entry ? entry.orderCount : 0;
};

export const getOrderReferenceListByPostalCode = (
  postalCode: string,
  data: any
) => {
  if (!data) {
    console.error("Data is undefined or null.");
    return []; // or handle the case accordingly
  }

  const entry = data.find((item: any) => item?.postalCode === postalCode);
  return entry ? entry.totalOrderReferences : [];
};

export const getCustomerOrdersSourceType = (source: string) => {
  if (source) {
    if (source === "app") {
      return "App";
    } else if (source === "BiteOrder") {
      return "Web";
    }
  }
  return "Web";
};

export const handleApiError = (err: any) => {
  let message = "";
  console.log(err, "err");

  if (err.response) {
    if (err.response.data.errors && err.response.data.errors.length) {
      err.response.data.errors.forEach((item: any) => {
        message = message.length ? message + ", " + item.msg : item.msg;
      });
    } else {
      message = err.response.data.message;
    }
  } else if (err.message) {
    message = err.message;
  } else {
    message = err;
  }
  if (message) {
    return message;
  } else {
    return "something went wrong";
  }
};
export const getEnvPath = (type: string, fileName: any) => {
  switch (type) {
    case "filePath":
      if (fileName && fileName.url) {
        // If fileName has a url property, use it
        return process.env.REACT_APP_PROFILE_PATH + fileName.url;
      } else if (fileName instanceof File || fileName instanceof Blob) {
        // If fileName is a File or Blob object, create a URL for it
        return URL.createObjectURL(fileName);
      } else {
        // Return an empty string if fileName is empty or not valid
        return "";
      }

    // Add more cases if needed
    default:
      return "";
  }
};

export const routeTitles: any = {
  "/dashboard": "Dashboard",
  "/settings": "Settings",
  "/coupons": "Coupons",
  "/orders": "Orders",
  "/conversion": "Conversion",
  "/insight": "Customer Insights",
  "/customer": "Customer Listing",
  "/termsAndConditions": "Terms And Conditions",
  "/customer/:id": "Customer Details",
  "/campaign/:id": "Advertisement Reports",
  "/promotional/coupon": "Promotional Coupons",
  "/promotional/wheel": "Promotional Wheel",
  "/promotional/reports": "Reports",
  "/promotional/advertiser": "Promotional Advertisement",
  "/promotional/promoday": "Promotional Promo Day",
  "/promotional/campaign": "Campaign Page",
  "/promotional/points": "Promotional Points",
  "/notifications": "Notifications",
  "/restaurant-listing": "Restaurant Position Listing",
  // Add more routes and their titles as needed
};
